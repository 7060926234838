import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import 'semantic-ui-css/semantic.min.css'
import './index.css';
import App from './App';
import FullSheetTable from './FullSheetTable';
import SampleZipsTable from './SampleZipsTable';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
   
      <Router> <Routes>
        <Route path="/" element={<App/>} />
        <Route path="sheet/:sessionId/:sheetName" element={<FullSheetTable/>} />
        <Route path="zips/:sessionId/:filter?" element={<SampleZipsTable/>} />
       </Routes></Router>
   
  </React.StrictMode>
);

reportWebVitals();
