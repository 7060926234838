/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';

import { CircularProgress, Button, Badge } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import { apiAddHeaderLineAndTrain, apiAddSheetToBq, apiStoreRates, apiSyncToCalc } from './api';

import AddHeaderRowForm from './modules/AddHeaderRow';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon';
import { getLocalStorage } from './getLocalStorage';
import { Popup } from 'semantic-ui-react';
const debug = require("debug")("status");

const StatusModule = ({ sessionId, statusData, setKeyValue, sheetName, step }: { sessionId: string | null, statusData: any, setKeyValue: any, sheetName: string | null, step: number }) => {
  debug("build status table");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedSheetNames, setSelectedSheetNames] = useState<string[]>([]); // Array to store selected lines
  const [runningSheets, setRunningSheets] = useState({});
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [toggleAll, setToggleAll] = useState(false);
  const finishedSheets = statusData && statusData.filter((el: any) => el && el.statusIdMax === 63);
  const finishedSheetsQty = finishedSheets.length;


  const toggleFrom = (sheetName: any) => {
    debug("open header form", sheetName);
    if (isFormOpen === sheetName) { setIsFormOpen(false); }
    else {
      setIsFormOpen(sheetName);
    }

  };
  const closeForm = () => {
    setIsFormOpen(false);
  };
  const handleFormSubmit = async (formData: any) => {
    setIsLoading(true);
    
    const { sheetName, columnNumber, rowNumber } = formData;
    // Handle the form submission here, e.g., make an API call
    console.log('Form submitted with data:', { sessionId, ...formData });
    closeForm();
    if (typeof sessionId === "string")
      await apiAddHeaderLineAndTrain({ sessionId, sheetName, columnNumber, rowNumber })
    // Close the form after submission
   
    setKeyValue("step", 2);// go back for reload
  };

  const uploadToCalc = async () => {
    setIsLoading(true); // Start the spinner
    try {
      debug("start upload");
      if (!sessionId) throw Error("sessionId should be set!");
      const sheetNames = selectedSheetNames;
      const result = await apiSyncToCalc({ sessionId, sheetNames });
      debug("end upload: %o", result);

    } finally {
      setIsLoading(false); // stop the spinner
    }
  }

  const toggleAllSelection =()=>{
    debug("finishedSheets", finishedSheets);
    
    finishedSheets.forEach((s: { sheetName: string; }) => {
      if(typeof s ==="object" ) toggleLineSelection(s.sheetName, !toggleAll)
    });
    setToggleAll(!toggleAll);

  }
  const toggleLineSelection = (sheetName: string, isChecked: boolean) => {
    // Toggle the selected state for the clicked line
    setSelectedSheetNames((selectedSheetNames) => {
      let result: string[];
  
      if (isChecked) {
        if (selectedSheetNames && selectedSheetNames.includes(sheetName)) {
          // If sheetName is already in the array, return the current array
          result = selectedSheetNames;
        } else {
          // If selectedSheetNames is undefined, initialize it with an array containing sheetName
          result = selectedSheetNames ? [...selectedSheetNames, sheetName] : [sheetName];
        }
        debug("selectedLines %o", selectedSheetNames, isChecked);
      } else {
        // Remove the sheetName from the array
        result = selectedSheetNames ? selectedSheetNames.filter((name) => name !== sheetName) : [];
      }
  
      return result;
    });
  };

  function checkNonEmptyString(val: any): string {
    if (typeof val === 'string' && val.length > 0) return val;
    throw Error("val is not a string")
  }

  const mappingStatus = (sheetName: string) => {

    checkNonEmptyString(sheetName);
    const obj: any = runningSheets[sheetName as keyof typeof runningSheets];
    if (!obj) return { status: "empty", message: "click to start mapping based on existing mapping!" };
    if (obj.error) return { status: "failed", message: obj.error };
    if (new Date(new Date(obj.date).getTime() + 2 * 60 * 1000) > new Date()) {
      // still running
      return { status: "running", message: "running for seconds :" + (new Date().getTime() - new Date(obj.date).getTime()) }
    }
    if (obj.date) return { status: "failed", message: "ran over 2 min" };
    return {
      status: "na",
      message: "na"
    }
  }
  const sendSheetToBq = async (startSheetName: string) => {
    try {
      debug("retry sheet to bq", startSheetName);
      //find sheet
      const sheetIndex = statusData.findIndex((el: { sheetName: string; })=>el.sheetName === startSheetName);
      statusData[sheetIndex].statusIdMax =0;
      const result = await apiAddSheetToBq({ sessionId: checkNonEmptyString(sessionId), sheetName: checkNonEmptyString(startSheetName) });
      debug("finish sendSheetToBq", startSheetName, runningSheets, result);
      statusData[sheetIndex].statusIdMax =23;

    } catch (error) {
      console.error("not able to sendSheetToBq ", error);

    }
  }



  const startMapping = async (startSheetName: string) => {
    try {
      debug("startMapping", startSheetName);
      const mapping = JSON.parse(checkNonEmptyString(getLocalStorage("mapping")));
      const dir = checkNonEmptyString(getLocalStorage("dir"));
      const index = checkNonEmptyString(getLocalStorage("index"));
      setRunningSheets({ ...runningSheets, [startSheetName]: { start: new Date() } });
      debug("start mapping %o", startSheetName, runningSheets);
      const result = await apiStoreRates({ sessionId: checkNonEmptyString(sessionId), sheetName: checkNonEmptyString(startSheetName), mapping, dir, index: Number(index) });
      debug("finish Mapping", startSheetName, runningSheets, result.data);
      if (result.data.errorMessage) {
        setRunningSheets({ ...runningSheets, [startSheetName]: { error: result.data.errorMessage } });
      }
      else {
        setRunningSheets({ ...runningSheets, [startSheetName]: null });

      }


    } catch (error) {
      console.error("not able to start mapping", error);
      setRunningSheets({ ...runningSheets, [startSheetName]: { error } });
    }
  }

  if (!sessionId) { return null }
  if (!statusData) {
    return <div>Loading status...</div>;
  }


  return (
    <div>
      <div>

        {/* Conditionally render the form */}
        {isFormOpen && typeof isFormOpen === "string" && (
          <AddHeaderRowForm onSubmit={handleFormSubmit} sheetName={isFormOpen} />
        )}
      </div>
      <table  className="statusTable">
        <thead>
          <tr>

            <th>Sheet Name</th>
            <th>Step</th>
            <th>Calculting</th>
            <th>Reload to BQ</th>
            <th>Modify Header</th>
            <th>Edit Data</th>
            <th>Build UBS</th>
            <th>Send to Calc
            <input
                  type="checkbox"
                  onChange={(e) => toggleAllSelection()}
                  checked={toggleAll}
                />

            </th>
          </tr>
        </thead>
        <tbody>
          {statusData.filter((el: any) => el.sheetName !== "*").map((status: any, index: number) => (
            <tr key={index}>

              <td>{status.sheetName === sheetName ? <Badge color="secondary" badgeContent=""> {status.sheetName} </Badge> : status.sheetName}</td>
              <td>{status.statusIdMax === 63 ? <DoneIcon color="success" /> : status.activeStep + "of6"}</td>
              <td>{status.running ? <CircularProgress size={10} /> : ''}</td>
              <td>{String(status.statusIdMax).substring(0,1)==="2" && status.statusIdMax !== 23 ? <Icon  onClick={() => sendSheetToBq(status.sheetName)} link name='play' color='blue' />: ""}</td>
              <td>
                <a href="" onClick={() => toggleFrom(status.sheetName)}><Icon disabled name='header' /></a></td>
              <td>
                <a href={"/sheet/" + status.sessionId + "/" + status.sheetName}> <EditIcon /></a></td>
              <td>
                <Popup
                  content={mappingStatus(status.sheetName).message}
                  trigger={<Icon link onClick={() => mappingStatus(status.sheetName).status !== "running" && startMapping(status.sheetName)}  loading={mappingStatus(status.sheetName).status === "running"} color={mappingStatus(status.sheetName).status === "failed" ? "red" : (status.statusIdMax === 63?"grey":"blue")} name='braille' />
                  }
                />
              </td>
              <td>
                <input
                  type="checkbox"
                  disabled={status.statusIdMax !== 63}
                  onChange={(e) => toggleLineSelection(status.sheetName, e.target.checked)}
                  checked={selectedSheetNames.includes(status.sheetName)}
                />

              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {isLoading ? <CircularProgress size={24} /> :
        <Button
          style={{ width: "40%", margin: "5%" }}
          id={"sendToMapping"}
          variant="contained"
          color="primary"
          onClick={() => uploadToCalc()}
          disabled={!finishedSheetsQty}
        >
          {!statusData ? <CircularProgress size={24} /> : "Start Rate Entry for " + selectedSheetNames.length + " sheets."}
        </Button >}
    </div>
  );
};

export default StatusModule;
