import React, { useState, useEffect } from 'react';
import { Button, CircularProgress, TextField } from '@mui/material';


import { apiGetHeaders, IApiResultLine } from './api';
const debug = require("debug")("headers");


interface ApiResultFormProps {
  sessionId: string | null;
  sheetName: string | null;
  setKeyValue: any;
  step: number;
}

// function DirectionSelect({ defaultValue, selectId }: { defaultValue: string, selectId: string }) {
//   return (
//     <Select defaultValue={defaultValue}  id={selectId} key={selectId} >
//       <Option key={selectId + "-r"} value={"r"}>R</Option>
//       <Option key={selectId + "-c"} value={"c"}>C</Option>
//     </Select>
//   );
// }


const ApiResultForm: React.FC<ApiResultFormProps> = ({ sessionId, setKeyValue, sheetName, step }) => {

  const [headers, setHeaders] = useState<IApiResultLine[]>([]);


  useEffect(() => {
    const fetchHeaders = async () => {
      try {
        if (typeof sessionId !== "string") return false;
        const result = await apiGetHeaders({ sessionId });
        debug("apiGetHeaders result %o", result);
        if (result && sheetName) {
          setHeaders(result);
          // setHeaders(result.filter(el=>el.sheetName===sheetName));
          return true;
        }
        else if (result) {
          setHeaders(result);
          return true;
        }
        else {
          setHeaders([]);
          return false;
        }

      } catch (error) {
        console.error('Error fetching status:', error);
        setHeaders([]);
        return false;
      }
    };

    fetchHeaders();
  }, [sessionId, sheetName]);
  if (!sessionId) { return null }




  const handleSubmit = (sheetName: string) => {
    debug({ sheetName, headers });
    const line = headers.find(el => el.sheetName === sheetName);
    const dirInput = document.getElementById(`dir-${sheetName}`) as HTMLSelectElement;
    const indexInput = document.getElementById(`index-${sheetName}`) as HTMLInputElement;



    debug({ dirInput, line, indexInput })
    const result = {
      ...line,
      dir: dirInput.value,
      index: Number(indexInput.value)
    };


    debug("call for Line:", result);
    setKeyValue("sheetName", sheetName);
    setKeyValue("dir", result.dir);
    setKeyValue("index", result.index);
    setKeyValue("ratesStored", false);
    setKeyValue("step", step + 1);
  };

  return (
    <>
       <h3>Select header</h3>
      {headers.length === 0 ? <CircularProgress size={24} /> : ''}
      {headers.map((header) => (
        <div className="wrapper" style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "50%", margin: "5px" }} key={"sheet-" + header.sheetName}>
          <div>
            <strong>{header.sheetName}</strong>
          </div>
          {/* <DirectionSelect
            selectId={"dir-" + header.sheetName}
            defaultValue={header.dir}
          /> */}
          <TextField
            id={"dir-" + header.sheetName}
            defaultValue={header.dir}
            type="text"
            label="Header Dir"
            variant="outlined"
            size="small"
          />
          <TextField
            id={"index-" + header.sheetName}
            defaultValue={header.index}
            type="text"
            label="Header Index"
            variant="outlined"
            size="small"
          />
          <Button
            style={{ maxWidth: '100px', height: '35px', marginLeft: '10px' }}
            id={"process-" + header.sheetName}
            variant="contained"
            color="primary"
            onClick={() => handleSubmit(header.sheetName)}
          >
            Process
          </Button>
        </div>

        )
        )

      }




    </>
  );
};

export default ApiResultForm;
