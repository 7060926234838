import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Typography from '@mui/material/Typography';



const debug = require("debug")("steps")

export const steps = [ 'File Upload', 'Extract Data', 'Headers ML', 'Mapping AI', 'Rates','Store UBS'];

export type IcalculateStep= {sessionId: any, sheetNames: any, sheetName: any, index: any, dir: any, mapping: any, ratesStored:null | boolean};

export const calculateStep = ({sessionId, sheetNames, sheetName, index, dir, mapping, ratesStored}:IcalculateStep) => {
  debug("calculateStep step ",{sessionId, sheetName, sheetNames, index, dir, mapping, ratesStored});
  if (sessionId && sheetName && index && dir &&  mapping && ratesStored) {
    debug(`Step 5: rateobject stored`);
    return 5;
  } 
  
  if (sessionId && sheetName && index && dir &&  mapping) {
    debug(`Step 4: mapping done , show rateObjects - ${mapping}`);
    return 4;
  }

  if (sessionId && sheetName && index && dir) {
    // Step3  logic using sheetName and dir
    debug(`Step 3: Headers selected, create mapping sheetName - ${sheetName}, dir - ${dir}, dir - ${index}`);
    return 3;
  }

  if (sessionId && sheetNames) {
    // Step 2 logic using sheetName and dir
    debug(`Step 2: ConvertXlsx done , select header sheetNames - ${sheetNames}`);
    return 2;
  }

  if (sessionId) {
    // Step 1 logic using sessionId and sheetNames
    debug(`Step 1: FileUpload done sessionId - ${sessionId}, sheetNames - ${sheetNames}`);
    return 1;
  }


  // Default case when none of the variables match the conditions
  debug("nothing has been set, so we are at 0=FileUpload")
  return 0;
};

interface StepperModlue {
  completedStep: number;
  currentStep: number;
  setKeyValue : any;
}

const  HorizontalStepper:  React.FC<StepperModlue>  = ({ completedStep=0 , currentStep = 0, setKeyValue  })=> {
  debug("build stepper : current step %o , completed %o", currentStep, completedStep);
  // const allStepsCompleted = currentStep===steps.length
  
  const [activeStep, setActiveStep] = React.useState<number>(Number(currentStep));
  debug("active step %o based on %o", activeStep, Number(currentStep));

  const isCompleted = (index:number) => {
    return completedStep-1>=index;
  };

  const isActive = (index:number) => {
    return activeStep===index+1;
  };



  const handleStep = (index: number) => () => {
    debug("set step", index);
    setActiveStep(index+1);
    setKeyValue("step", index+1)
  };



  return (
    <Box sx={{ width: '100%' }}>
      <Stepper nonLinear activeStep={currentStep-1} >
        {steps.map((label, index) => (
          <Step key={label} completed={isCompleted(index)} >
            <StepButton color="inherit" onClick={handleStep(index)}>
              {label}
              {isActive(index)?
              <Typography variant="caption" color="error">
                (active)
              </Typography>:null}
            </StepButton>
          </Step>
        ))}
      </Stepper>
     
    </Box>
  );
}


export default HorizontalStepper