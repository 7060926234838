import React from 'react';
import { Box, Typography } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import clsx from 'clsx';
import { toast } from 'react-toastify';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';

import { IFileUploadProps } from './FileUploadProps';
import { apiGetSessionId } from '../api';


const debug = require("debug")("fileupload");

const FileUpload: React.FC<IFileUploadProps> = ({
  accept = "*",

  hoverLabel = 'Drag to upload file',
  dropLabel = 'Drop file here',
  width = '600px',
  height = '100px',
  backgroundColor = '#fff',
  setKeyValue,
  step
}) => {
  const [labelText, setLabelText] = React.useState<string>(hoverLabel);
  const [isDragOver, setIsDragOver] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const handleFileChange = async (selectedFile: File) => {
    debug("start handle");
    setIsLoading(true);
    try {


      if (!selectedFile) {

        toast.warn('Please select a file to upload.');
        return;
      }
      debug("selectedFile %o", selectedFile.name);

      // Call your API to get the pre-signed URL
      const { url, sessionId } = await apiGetSessionId({ fileName: selectedFile.name });

      if (!url) throw Error("no url");
      if (!sessionId) throw Error("no sessionId");
      // Upload the file to the pre-signed URL


      const uploadDone = await axios.put(url, selectedFile, {
        headers: {
          'Content-Type': 'application/octet-stream',
          "Access-Control-Allow-Origin": "*"
        },
      });
      debug("uploadDone %o", uploadDone)
      setKeyValue("sessionId", sessionId);
      setKeyValue("fileName", selectedFile.name);
      setKeyValue("step", step+1);
      toast.success('File upload successful');
    } catch (error) {
      console.error('Error uploading file:', error);
      toast.error('An error occurred while uploading the file.');

    } finally {
      setIsLoading(false);
    }
  };

  const stopDefaults = (e: React.DragEvent) => {
    //debug("event", e);
    e.stopPropagation();
    e.preventDefault();
  };

  const dragEvents = {

    onDragEnter: (e: React.DragEvent) => {
      stopDefaults(e);
      setIsDragOver(true);
      setLabelText(dropLabel);
    },
    onDragLeave: (e: React.DragEvent) => {
      stopDefaults(e);
      setIsDragOver(false);
      setLabelText(hoverLabel);
    },
    onDragOver: stopDefaults,
    onDrop: (e: React.DragEvent<HTMLElement>) => {
      stopDefaults(e);
      setLabelText(hoverLabel);
      handleFileChange(e.dataTransfer.files[0]);
      setIsDragOver(false);


    },
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    debug(`onChange event`, event);

  };

  return (
    <>
      <input onChange={handleChange} accept={accept} type="file" style={{ display: 'none' }} />

      <label
        htmlFor="file-upload"
        {...dragEvents}
        className={clsx(isDragOver && 'onDragOver')}
        style={{ cursor: 'pointer', textAlign: 'center', display: 'flex', position: 'relative' }}
      >
        <Box
          width={width}
          height={height}
          bgcolor={backgroundColor}
          style={{ pointerEvents: 'none' }}
        >

          <Box
            height={height}
            width={width}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              position: 'absolute',

            }}
          >
            <CloudUploadIcon fontSize="large" />
            {isLoading ? <CircularProgress size={24} /> : <Typography>{labelText}</Typography>}

          </Box>

        </Box>
      </label>
    </>
  );
};

export default FileUpload;
