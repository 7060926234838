import React, { useState, useEffect } from 'react';
import { apiGetSheetData, apiStoreSheetDataCorrection } from './api';
import Table from './modules/Table';
import { CircularProgress } from '@mui/material';
import { useParams } from 'react-router-dom';
import FormDialog from './modules/FormDataCorrection';

const debug = require("debug")("fullsheet");




const FullSheetTable = () => {
  const { sessionId, sheetName } = useParams();
  debug("params %o ", { sessionId, sheetName });
  const emptyTable = { rows: [], columns: [] };
  const [isLoading, setIsLoading] = useState(true);
  const [formData, setFormData] = useState<any>(false);
  const [apiData, setApiData] = useState<{ rows: any[], columns: any[] }>(emptyTable);

  const CustomCell = () => (row: any, index: any, column: { id: number; }) => {
    
    const clickableCellStyle = {
      cursor: 'pointer',
    };
  
    const handleClick = () => {
      const modalData = {
        rowNumber: row[0],
        columnNumber: column.id - 1,
        data: row[column.id - 1],
        sessionId, sheetName
      };
  
      debug("clicked on", modalData);
      setIsLoading(true);
      setFormData(modalData);
    };
  
    return (
      <div style={clickableCellStyle} onClick={handleClick}>
        {row[column.id - 1]}
      </div>
    );
  };
  const fetch = async ({ sessionId, sheetName }:{ sessionId:string, sheetName:string}) => {
    if (typeof sessionId !== "string" || typeof sheetName !== "string") return false;
  
    try {
      debug("call api apiGetSheetData %o", { sessionId, sheetName });
      const data = await apiGetSheetData({ sessionId, sheetName });
      debug("api apiGetSheetData result %o", data);
      let { rows, columns } = data;
      const defaultColumnSettings = {
        maxWidth: "100px",
        sortable: true,
        cell: CustomCell()
      };
  
      columns = columns.map((column: any, i: string | number) => ({ ...defaultColumnSettings, name: column, selector: (row: { [x: string]: any; }) => row[i] }));
      return { rows, columns };
    } catch (e) {
      console.error(e);
      throw e;
    }
  };
  
  const handleClose = async (correction: any) => {
    debug("correction", correction);
    Object.keys(correction).forEach(key => {
      if (key.includes("Select") && correction[key] === false) {
        delete correction[key.replace("Select", "")];
        delete correction[key];
      }
    })
    setFormData(false);
    setIsLoading(false);
    debug("correction after clean:%o", correction);
    if (correction.dataCorrection) await apiStoreSheetDataCorrection(correction);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!sessionId || !sheetName) return null;

      debug("run fetch");
      setIsLoading(true); // Start the spinner
      try {
        const data = await fetch({ sessionId, sheetName });
        data && setApiData(data);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId, sheetName]);

  if (!sessionId || !sheetName) return null;

  return (
    <>
      <h3>Sheet Data</h3>

      <div className="wrapper" style={{ width: "100%", margin: "15px" }}>
        {isLoading ? (
          <CircularProgress size={24} />
        ) : (
          <Table rows={apiData.rows} columns={apiData.columns} pagination={true}></Table>
        )}
      </div>
      {formData && <FormDialog formData={formData} handleClose={handleClose} />}
    </>
  );
};

export default FullSheetTable;
