import React, { useState } from 'react';
import { Form, Input, Button, Radio } from 'semantic-ui-react';




const HeaderForm = ({ onSubmit, sheetName }: { onSubmit: any, sheetName : string}) => {
  const [lineNumber, setLineNumber] = useState<{ rowNumber?: number; columnNumber?: number }>({});
  const [selectedField, setSelectedField] = useState<'rowNumber' | 'columnNumber'>('rowNumber');
  const submitForm = async () => {
    try {
      // Your API call logic goes here
      console.log('API call with rowNumber:', lineNumber);
      onSubmit({sheetName,...lineNumber})
    } catch (error) {
      console.error('Error:', error);
    }
  };
  return (
    <Form>
      <Form.Field>
        <label>Set Header Row for {sheetName}</label>
        <Radio
          label='Row Number'
          name='radioGroup'
          value='rowNumber'
          checked={selectedField === 'rowNumber'}
          onChange={() => setSelectedField('rowNumber')}
        />
        <Radio
          label='Column Number'
          name='radioGroup'
          value='columnNumber'
          checked={selectedField === 'columnNumber'}
          onChange={() => setSelectedField('columnNumber')}
        />
      </Form.Field>
      <Form.Field>
        <Input
          type="number"
          placeholder={`Enter ${selectedField === 'rowNumber' ? 'row' : 'column'} number`}
          value={lineNumber[selectedField]}
          onChange={(e, { value }) => selectedField === 'rowNumber' ? setLineNumber({rowNumber: Number(value)}) : setLineNumber({columnNumber:Number(value)})}
        />
      </Form.Field>
      <Button primary onClick={submitForm}>
        Confirm
      </Button>
    </Form>
  );
};

export default HeaderForm;
