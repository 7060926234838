import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { apiGetAiMapping, apiGetStatusSheet, apiGetTableHeadWithSamples } from './api';

import Table from './modules/Table';
import { TextField, Button, CircularProgress } from '@mui/material';
import JsonEditor from './modules/JsonEditor';

const debug = require("debug")("headermapping");


interface ApiResultFormProps {
  sessionId: string | null;
  setKeyValue: any;
  sheetName: any;
  dir: any;
  index: any;
  mapping: any;
  step: number;
}

// function DirectionSelect({ defaultValue, selectId }: { defaultValue: string, selectId: string }) {
//   return (
//     <Select defaultValue={defaultValue}  id={selectId} key={selectId} >
//       <Option key={selectId + "-r"} value={"r"}>R</Option>
//       <Option key={selectId + "-c"} value={"c"}>C</Option>
//     </Select>
//   );
// }


const HeaderMapping: React.FC<ApiResultFormProps> = ({ sessionId, setKeyValue, step, sheetName, dir, index, mapping: existingMapping }) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const emptyTable = { rows: [], columns: [] };
  const emptyMapping = { schema: {}, json: null };


  const [isLoading, setIsLoading] = useState(false);
  const [apiData, setApiData] = useState<{ rows: any[], columns: any[], error?: string }>(emptyTable);
  const [mapping, setMapping] = useState<{ schema: any, json: any | null }>({ ...emptyMapping, json: typeof existingMapping === "string" ? JSON.parse(existingMapping) : existingMapping });

  if (!sessionId) throw Error("sessionId should be set!");
  const confirmMapping = () => {
    setKeyValue("mapping", mapping.json);
    setKeyValue("step", step + 1);
  }

  const updateMappingJson = (mappingJson: any) => {
    mapping.json = mappingJson;
    setMapping(mapping);
  }

  const handleSubmit = async () => {
    try {
      const functionName = undefined;
      setIsLoading(true); // Start the spinner
      setMapping(emptyMapping);
      const extraContext = document.getElementById(`extraContext`) as HTMLSelectElement;
      debug("extraContext %o", extraContext.value);
      const { table, schema, error } = await apiGetAiMapping({ sessionId, sheetName, dir, index: Number(index), mappingCorrections: [extraContext.value], functionName });
      debug("set json on mapping %o", { table, schema, error });

      if (table) {
        setMapping({ json: table, schema: schema });
        setIsLoading(false); // stop the spinner
      }
      if (error) {
        toast.error(error);
        setIsLoading(false); // stop the spinner
      }
    } finally {

    }
  }


  useEffect(() => {
    const fetchHeader = async () => {
      try {

        if (typeof sessionId !== "string") return false;
        if (typeof sheetName !== "string") return false;
        const result = await apiGetTableHeadWithSamples({ sessionId, sheetName, dir, index });
        debug("api GetTableHeadWithSamples result %o", result);
        if (result.columns && result.rows && !result.error) {
          setApiData(result);
          return true;
        }
        else if (result.error) {
          console.error('Error fetching status:', result.error);
          setApiData(emptyTable);
          return false;
        }
        else {
          setApiData(emptyTable);
          return false;
        }

      } catch (error) {
        console.error('Error fetching status:', error);
        setApiData(emptyTable);
        return false;
      }
      finally {

      }
    };

    fetchHeader();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId, sheetName, dir, index]);


  useEffect(() => {

    const fetchPreviousMappings = async () => {
      setIsLoading(true); // Start the spinner
      try {

        if (typeof sessionId !== "string") return false;
        if (typeof sheetName !== "string") return false;
        const result = await apiGetStatusSheet({ sessionId, sheetName });
        if (!result || typeof result !== "object") {
          setIsLoading(false); // Stop the spinner
          return false;
        }

        const { mappingResult, extraPrompts, running, fail } = result
        debug("api GetStatusSheet result %o", mappingResult);
        if (mappingResult?.table) {
          setMapping({ json: mappingResult?.table, schema: mappingResult?.schema });
          setIsLoading(false); // Stop the spinner
          if (extraPrompts && extraPrompts[0]) {
            debug("set extra prompts", extraPrompts)
            const extraContext = document.getElementById(`extraContext`) as HTMLSelectElement;
            extraContext.value = extraPrompts.join("/n");
          }
          return true;
        }
        else if (fail) {
          setIsLoading(false); // Stop the spinner
          return false;
        }
        // Check if the conversion is complete
        else if (running === true) {
          debug("poll api to see progress, status:", running, fail)
          setTimeout(fetchPreviousMappings, 20000); // Poll API every 20 seconds
          return true;
        }
        setIsLoading(false);
        return false
      } catch (error) {
        setIsLoading(false);
        console.error('Error fetching status:', error);

        return false;
      }
      finally {
        return false;

      }
    };

    if (!existingMapping) fetchPreviousMappings();
  }, [existingMapping, sessionId, sheetName]);






  //
  if (!sessionId || !sheetName || !dir || !index) { return null }

  if (!apiData) {
    return <div>Loading status...</div>;
  }





  return (
    <>
      <h3>Confirm headers</h3>




      <div className="wraper" style={{ width: "100%" }}>

        <Table rows={apiData.rows} columns={apiData.columns}></Table>

        <TextField
          id="extraContext"
          label="Add additional context"
          multiline
          maxRows={4}
          style={{ width: "100%" }}
        />
        <Button
          style={{ width: "25%", margin: "1%" }}
          id={"sendToMapping"}
          variant="contained"
          color="primary"
          onClick={() => handleSubmit()}
          disabled={isLoading}
        >
          {isLoading ? <CircularProgress size={24} /> : mapping.json ? "Re-map with AI" : "Get AI mapping"}
        </Button >




        <Button
          style={{ width: "25%", margin: "1%" }}
          id={"sendToMapping"}
          variant="contained"
          color="primary"
          onClick={confirmMapping}
          disabled={isLoading || !mapping.json}
        >
          Confirm mapping and build rates
        </Button >

        {mapping.json && mapping.schema && <JsonEditor json={mapping.json} updateMappingJson={updateMappingJson} schema={mapping.schema} />}

      </div>



    </>





  );
};

export default HeaderMapping;
