import React from 'react';
import {  Button } from '@mui/material';
const debug = require("debug")("localstorage");

const LocalStorageContent = ({storeLocalStorage, getLocalStorage }) => {
  const keysToDisplay = ['fileName', 'sessionId', 'sheetNames', 'index', 'dir', 'sheetName', 'mapping', 'ratesStored'];
  const handleDeleteClick = (key) => {
    debug("delete key %o", key);
    storeLocalStorage(key,null);
  };
  const localStorageContent = keysToDisplay.reduce((content, key) => {
    const value = getLocalStorage(key);
    if (value) {
      content.push(
        <div key={key}>
          <strong>{key}:</strong> {value}
          <Button
          style={{ width: "50px" , margin: "1px" }}
          variant="outlined"
          color="primary"
          onClick={() => handleDeleteClick(key)}
        >
         delete
        </Button >
        </div>
      );
    }
    return content;
  }, []);

  return (
    <div>
      <h4>Processed:</h4>
      {localStorageContent}
      <a href="/?reset=1">reset</a>
    </div>
  );
};

export default LocalStorageContent;
