
import axios from 'axios';
import { IFunctionName, IMappingStatus } from "tenderify-ai/types";

const debug = require("debug")("api");
console.log("env", process.env);
const server = "https://eu-de.functions.appdomain.cloud/api/v1/web/ad4c13f2-5f38-41cc-876a-58a90c5953d9/tenderify/api.json";
const local = "http://localhost:4000/";

const POST_URL = process.env.NODE_ENV === "development" ? local : server;

let last = +new Date();

function checkRateLimit() {
  const now = +new Date();
  if (now - last < 200) { // 5 seconds
    throw new Error("rate limit reached!")
  }
}

export type IApiStatus =
  {
    sessionId: string,
    sheetName: string,
    statusIdMax: number,
    activeStep: number | string | null
  }[] | [];

export async function apiGetStatus({ sessionId }: { sessionId: string }) {
  debug("fetch status for ", sessionId);
  checkRateLimit();
  if (!sessionId) return [];
  const response: {
    data: {
      result: IApiStatus,
    }
    status: "ok" | "nok",
  } = await axios.post(POST_URL, { type: 'status', request: { sessionId } }, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  debug("response apiGetStatus: %o", response.data)
  return response.data.result || [];
}

export async function apiAddHeaderLineAndTrain({ sessionId, sheetName, rowNumber, columnNumber }: { sessionId: string, sheetName: string, rowNumber?: number, columnNumber?: number }) {
  debug("fetch status for ", sessionId);
  checkRateLimit();
  if (!sessionId) return [];
  const response: {
    data: {
      result: any,
    }
    status: "ok" | "nok",
  } = await axios.post(POST_URL, { type: 'addHeaderLineAndTrain', request: { sessionId, sheetName, rowNumber, columnNumber } }, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  debug("response apiGetStatus: %o", response.data)
  return response.data.result || [];
}


export async function apiGetStatusSheet({ sessionId, sheetName }: { sessionId: string, sheetName: string }): Promise<IMappingStatus | null> {
  debug("fetch status for ", sessionId);
  checkRateLimit();
  if (!sessionId) return null;
  const response: {
    data: {
      result: IMappingStatus,
    }
    status: "ok" | "nok",
  } = await axios.post(POST_URL, { type: 'status', request: { sessionId, sheetName } }, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  debug("response apiGetStatusSheet : %o", response.data)
  return response?.data?.result || null;
}


export async function apiGetSheetData({ sessionId, sheetName }: { sessionId: string, sheetName: string }): Promise<any | null> {
  debug("fetch status for ", sessionId);
  checkRateLimit();
  if (!sessionId) return null;
  const response: {
    data: {
      result: { rows: any[], columns: any[] },
    }
    status: "ok" | "nok",
  } = await axios.post(POST_URL, { type: 'getSheetData', request: { sessionId, sheetName } }, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  debug("response apiGetSheetData : %o", response.data);
  if (!response?.data?.result) return null;
  let { rows, columns } = response?.data?.result;

  return { columns, rows };
}

export async function apiGetCellData({ sessionId, sheetName, rowNumber, columnNumber }: { sessionId?: string, sheetName?: string, rowNumber?: number, columnNumber?: number }): Promise<any | null> {
  debug("get data for %o", { sessionId, sheetName, rowNumber, columnNumber });
  checkRateLimit();
  if (!sessionId) return null;
  const response: {
    data: {
      result: any,
    }
    status: "ok" | "nok",
  } = await axios.post(POST_URL, { type: 'getCellData', request: { sessionId, sheetName, rowNumber, columnNumber } }, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  debug("response apiStoreSheetDataCorrection : %o", response.data);

  return response?.data?.result;
}


export async function apiStoreSheetDataCorrection({ sessionId, sheetName, rowNumber, columnNumber, data, dataCorrection }: { sessionId?: string, sheetName?: string, rowNumber?: number, columnNumber?: number, data: string, dataCorrection: string }): Promise<any | null> {
  debug("store data correction %o to %o", data, dataCorrection);
  checkRateLimit();
  if (!sessionId) return null;
  const response: {
    data: {
      result: any,
    }
    status: "ok" | "nok",
  } = await axios.post(POST_URL, { type: 'storeSheetDataCorrection', request: { sessionId, sheetName, rowNumber, columnNumber, data, dataCorrection } }, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  debug("response apiStoreSheetDataCorrection : %o", response.data);
  if (!response?.data?.result) return null;
  let { rows, columns } = response?.data?.result;

  return { columns, rows };
}



export async function apiGetSessionId({ fileName }: { fileName: string }) {
  debug("apiGetSessionId %o", fileName);
  // Call your API to get the pre-signed URL
  checkRateLimit();
  const response = await axios.post(POST_URL, { type: 'getUploadUrl', request: { fileName } },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  const signedUrlResponse = await response.data;
  debug("response signed url %o", signedUrlResponse);
  const { url, sessionId } = signedUrlResponse.result;
  return { url, sessionId };
}
export async function apiXlsxToBq({ sessionId }: { sessionId: string }) {
  checkRateLimit();
  if (!sessionId) throw Error("sessionId missing!");
  // Call your API to get the pre-signed URL
  const response = await axios.post(POST_URL, { type: 'xlsxToBq', request: { sessionId } },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  debug("response apiGetSessionId %o", response);
}

export async function apiAddSheetToBq({ sessionId ,sheetName}: { sessionId: string,  sheetName:string }) {
  checkRateLimit();
  if (!sessionId) throw Error("sessionId missing!");
  if (!sheetName) throw Error("sessionId missing!");
  // Call your API to get the pre-signed URL
  const response = await axios.post(POST_URL, { type: 'addSheetToBq', request: { sessionId,  sheetName } },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  debug("response apiGetSessionId %o", response);
}


export interface IApiResultLine {
  sessionId: string;
  sheetName: string;
  dir: string;
  index: number;
}


export async function apiGetHeaders({ sessionId }: { sessionId: string }) {
  checkRateLimit();
  if (!sessionId) throw Error("sessionId missing!");
  // Call your API to get the pre-signed URL
  const response: {
    data: {
      result: IApiResultLine[] | [],
    }
    status: "ok" | "nok",
  } = await axios.post(POST_URL, { type: 'getHeaders', request: { sessionId } },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  debug("response apiGetHeaders %o", response);

  return response.data.result;
}





export async function apiGetTableHeadWithSamples({ sessionId, sheetName, dir, index }: { sessionId: string, sheetName: string, dir: string, index: number }) {
  checkRateLimit();
  if (!sessionId) throw Error("sessionId missing!");
  // Call your API to get the pre-signed URL
  const response: {
    data: {
      result: {
        csv: string,
        json: any[] | [],
      }
    }
    status: "ok" | "nok",
  } = await axios.post(POST_URL, { type: 'getTableHeadWithSamples', request: { sessionId, sheetName, dir, index: Number(index) } },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  debug("response apiGetTableHeadWithSamples %o", response);
  if (!Array.isArray(response.data?.result?.json)) return { error: "no data objects found" };
  const columns = Object.keys(response.data.result.json[0]).map(column => {

    return { name: column, selector: (row: any) => row[column], maxWidth: "300px" }
  });

  return { rows: response.data.result.json.map((el) => { return { id: el.index, ...el, } }), columns };
}




export async function apiGetAiMapping({ sessionId, sheetName, dir, index, mappingCorrections = [], functionName }: { sessionId: string, sheetName: string, dir: string, index: number, mappingCorrections: string[] | null, functionName?: IFunctionName }) {
  if (!sessionId) throw Error("sessionId missing!");
  // Call your API to get the pre-signed URL
  const response: {
    data: {
      errorMessage?: string,
      result: {
        input: any[] | [],
        output: {
          schema: any; table: any
        },
      }
    }
    status: "ok" | "nok",
  } = await axios.post(POST_URL, { type: 'getAiMapping', request: { sessionId, sheetName, dir, index: Number(index), mappingCorrections, functionName } },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  debug("response apiGetAiMapping %o", response);


  return { table: response.data?.result?.output?.table, schema: response.data?.result?.output?.schema, error: response.data?.errorMessage };
}




export async function apiGetRates({ sessionId, sheetName, dir, index, mapping, page = 1 }: { sessionId: string, sheetName: string, dir: string, index: number, mapping: any, page: number }) {
  if (!sessionId) throw Error("sessionId missing!");
  // Call your API to get the pre-signed URL
  debug("call api getRateObjects %o", { sessionId, sheetName, dir, index, mapping, page });
  const response: {
    data: {
      result: {
        rateObjects: any[] | []
      }
    }
    status: "ok" | "nok",
  } = await axios.post(POST_URL, { type: 'getRateObjects', request: { sessionId, sheetName, dir, index: Number(index), mapping, page } },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  debug("response apiGetRates %o", response);

  const defaultColumnSettings = { maxWidth: "200px", sortable: true }
  const columns: any[] = [{ ...defaultColumnSettings, name: "lineId", selector: (row: any) => row.lineId }]; // we already set id
  const rateColumns: any[] = [];
  if (!Array.isArray(response.data?.result?.rateObjects)) {

    console.warn("no rate objects found");
    return { rows: [], columns: [] };
  }

  Object.keys(response.data.result.rateObjects[0]).forEach(column => {

    if (column === "main/freight") {
      Object.keys(response.data.result.rateObjects[0][column]).forEach(rate => {
        debug("add column for", column, rate)
        rateColumns.push({ ...defaultColumnSettings, name: column + "-" + rate, selector: (row: any) => row[column][rate].mapping });
      })

    }
    else if (column !== "lineId") {
      columns.push({ ...defaultColumnSettings, name: column, selector: (row: any) => Object.keys(row[column]).includes("mapping") ? JSON.stringify(row[column].mapping) : JSON.stringify(row[column]), maxWidth: "200px" });
    }

  });
  return { columns: [...columns, ...rateColumns], rows: response.data?.result?.rateObjects };
}



export async function apiStoreRates({ sessionId, sheetName, mapping, dir, index }: { sessionId: string, sheetName: string, mapping: any, dir: string, index: number }) {
  if (!sessionId) throw Error("sessionId missing!");
  // Call your API to get the pre-signed URL
  debug("call api storeRateObjects %o", { sessionId, sheetName, mapping });
  const response: {
    status: "ok" | "nok",
    data:{errorMessage?:string, status:string}
  } = await axios.post(POST_URL, { type: 'storeRateObjects', request: { sessionId, sheetName, mapping, dir, index } },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  debug("response apiStoreRates %o", response);

  return response;
}


export async function apiSyncToCalc({ sessionId, sheetNames }: { sessionId: string, sheetNames: string[] }) {
  checkRateLimit();
  if (!sessionId) throw Error("sessionId missing!");
  // Call your API to get the pre-signed URL
  const response: {

    status: "ok" | "nok",
  } = await axios.post(POST_URL, { type: 'toCalc', request: { sessionId, sheetNames } },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  debug("response apiGetHeaders %o", response);

  return response.status;
}



export async function apiGetSampleZips({ sessionId, filter }: { sessionId: string, filter:string|undefined }) {
  checkRateLimit();
  if (!sessionId) throw Error("sessionId missing!");
  // Call your API to get the pre-signed URL
  const response: {
    data: { result: any[] }
    status: "ok" | "nok",
  } = await axios.post(POST_URL, { type: 'getSampleZipsInData', request: { sessionId, filter } },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  debug("response apiGetHeaders %o", response);

  return { rows: response.data?.result };
}



export async function apiUpdateSampleZip({ CC,
  zipRangeFrom,
  zipRangeTo,
  zipExamplesSample }: {
    CC: string,
    zipRangeFrom: string,
    zipRangeTo: string,
    zipExamplesSample: string
  }) {
  checkRateLimit();
  checkRateLimit();
  if (!zipExamplesSample) throw Error("zipExamplesSample missing!");
  // Call your API to get the pre-signed URL
  const response: {

    status: "ok" | "nok",
  } = await axios.post(POST_URL, {
    type: 'addSampleZip', request: {
      CC,
      zipRangeFrom,
      zipRangeTo,
      zipExamplesSample
    }
  },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  debug("response apiGetHeaders %o", response);

  return true;
}
