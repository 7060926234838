import React, { useState, useEffect } from 'react';
import { apiGetSampleZips, apiUpdateSampleZip } from './api';
import Table from './modules/Table';
import { Button, CircularProgress, TextField } from '@mui/material';
import { useParams } from 'react-router-dom';

const debug = require("debug")("zips");

const EditForm = ({ data, modifySampleZip, handleSaveClick, handleCancelClick }:{ data:any,modifySampleZip:any, handleSaveClick:any, handleCancelClick:any }) => (
  <>  Modify the sample zip for {data.CC} from zip {data.zipRangeFrom} to  {data.zipRangeTo}
    <TextField
      value={data.zipExamplesSample||""}
      onChange={(e)=>modifySampleZip( e.target.value)}
      variant="outlined"
      size="small"
    />
    <Button onClick={handleSaveClick} size="small" variant="outlined" color="primary">
      Save
    </Button>
    <Button onClick={handleCancelClick} size="small" variant="outlined" color="secondary">
      Cancel
    </Button>
  </>
);

const SampleZipsTable = () => {
  const { sessionId, filter } = useParams();

  debug("params %o ", { sessionId });
  const emptyTable = { rows: [], columns: [] };
  const [isLoading, setIsLoading] = useState(true);
  const [apiData, setApiData] = useState<{ rows: any[], columns: any[] }>(emptyTable);

  const [editedValue, setEditedValue] = useState(null);

  const handleCancelClick = () => {
    debug("cancel edit");

    setEditedValue(null);
  };
  const handleSaveClick = async () => {
    debug("store new samplezip", editedValue);
    try {
      setIsLoading(true);
      if(!editedValue) throw Error("missing editedValue");
      const api = apiUpdateSampleZip(editedValue);
      setEditedValue(null);
      await api;
      // Optionally, you can fetch the updated data after making the correction
      if (!sessionId || typeof sessionId !== "string") throw Error("missing sessionId");
      const { rows } = await apiGetSampleZips({ sessionId, filter });
      setApiData(prevState => ({ ...prevState, rows }));
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  const modifySampleZip = (zipExamplesSample: string) => {
    debug("new zipExamplesSample", zipExamplesSample);
    setEditedValue((prevValue:any) => ({
      ...prevValue,
      zipExamplesSample: zipExamplesSample,
    }));
    

  };


  const CustomCell = () => (row: any, index: any, column: { name: string }) => {
    const clickableCellStyle = {
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center', // Align items vertically in the flex container
    };



    const handleEditClick = () => {
      debug("start edit", row, { index }, column.name);
      setEditedValue(row);
      

    };


   


    //debug("row", {name:column.name, value:row[column.name], index});
    return (
      <div style={clickableCellStyle} id={row.index}>
       
          <>
            <div onClick={handleEditClick}>{row[column.name]}</div>
          </>
        
      </div>
    );
  };

  const fetchTableData = async ({ sessionId }: { sessionId: string }) => {
    if (!sessionId || typeof sessionId !== "string") throw Error("missing sessionId");

    try {
      debug("call api apiGetSheetData %o", { sessionId });
      const { rows } = await apiGetSampleZips({ sessionId, filter });
      let headers = ["CC", "zipRangeFrom", "zipRangeTo", "zipExamplesSample"];
      debug("api apiGetSheetData result %o", rows);

      const defaultColumnSettings = {
        maxWidth: "300px",
        sortable: true,
        cell: CustomCell()
      };

      const columns = headers.map((column: any, i: string | number) => ({ ...defaultColumnSettings, name: column, selector: (row: { [x: string]: any; }) => row[column] }));


      return { rows, columns };
    } catch (e) {
      console.error(e);
      throw e;
    }
  };

  useEffect(() => {
    if (!sessionId || typeof sessionId !== "string") throw Error("missing sessionId");
    const fetchData = async () => {


      debug("run fetch");
      setIsLoading(true); // Start the spinner
      try {
        const data = await fetchTableData({ sessionId });
        data && setApiData(data);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ sessionId]);

  if (typeof sessionId !== "string") return null;

  return (
    <>
      <h3>Sample Zips</h3>

      <div className="wrapper" style={{ width: "100%", margin: "15px" }}>
        {isLoading ? (
          <CircularProgress size={24} />
        ) : (
          <Table rows={apiData.rows} columns={apiData.columns} pagination={apiData.rows?.length>25}></Table>
        )}
      </div>
      {editedValue && <EditForm data={editedValue} modifySampleZip={modifySampleZip} handleSaveClick={handleSaveClick} handleCancelClick={handleCancelClick} />}
    </>
  );
};

export default SampleZipsTable;
