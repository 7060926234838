
import * as React  from 'react';


import DataTable from 'react-data-table-component';


const debug = require("debug")('table');

export function Table({ rows, columns , pagination=true}: { columns: any[], rows: any[] , pagination?:boolean}) {
  debug("column order %o", columns);
  if(columns.length===0) return null;
  return (
    <div style={{ width: '100%' }}>
      <DataTable
        columns={columns}
        data={rows}
        dense
        pagination={pagination}

      />
    </div>
  );
}

export default Table;