import React from 'react';

import JSONEditor from "./Vanilla-Json-Editor";
import {  createAjvValidator} from "vanilla-jsoneditor";
import { toast } from 'react-toastify';
import "jsoneditor/dist/jsoneditor.css";
import Ajv from 'ajv';
const debug = require("debug")("jsoneditor");
const ajv = new Ajv({ allErrors: true, verbose: true});

const JsonEditor = ({ json, schema, updateMappingJson }: { json: any, schema: any , updateMappingJson:any}) => {
  // validate json against schema
  debug("json %o", json)
  function validateSchema(json: any) {
    return ajv.validate(schema, json)

  }


  const handleChange = (newMapping: any) => {
    debug("JSON edit %o", newMapping);
    // Assuming newMapping is an object with a 'json' property
    const jsonData = newMapping.json;

    // Validate the JSON data
    const isValid = validateSchema(jsonData);

    if (isValid) {
      debug("Valid JSON!");
      toast.success('JSON updated');
      updateMappingJson(jsonData);
    } else {
      debug("errors %o", ajv.errors);
      toast.error('JSON not updated, is not valid!'); 
    }
  }


  return (
    <>

      <JSONEditor
        content={{json}}
        readOnly={false}
        onChange={handleChange} 
        validator={createAjvValidator({ schema })}
        />
        
        </>
  );
}

export default JsonEditor;