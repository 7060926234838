import React, { useState, useEffect } from 'react';

import { apiGetRates, apiStoreRates } from './api';
import Table from './modules/Table';
import { Button, CircularProgress } from '@mui/material';

const debug = require("debug")("rateobjects");



interface ApiResultFormProps {
  sessionId: string | null;
  setKeyValue: Function;
  sheetName: string | null;
  dir: string | null;
  index: number | string | null;
  mapping: any;
  step: number;
}



const RateObjects: React.FC<ApiResultFormProps> = ({ sessionId, setKeyValue, sheetName, dir, index, mapping: existingMapping, step }) => {
  const emptyTable = { rows: [], columns: [] };
  const [isLoading, setIsLoading] = useState(false);
  const [page] = useState(1);
  const [apiData, setApiData] = useState<{ rows: any[], columns: any[] }>(emptyTable);
  const mapping = typeof existingMapping === "string" ? JSON.parse(existingMapping) : existingMapping;

  if (!sessionId || !sheetName) throw Error("sessionId and sheetName should be set!");
  if (!dir) throw Error("dir should be set!");


  const handleSubmit = async () => {
    try {
      setIsLoading(true); // Start the spinner
      // call function to store mapping into bq
      const response = await apiStoreRates({ sessionId, sheetName, mapping, dir, index :Number(index)});
      setKeyValue("ratesStored", true);
      setKeyValue("step", step + 1);
      debug("apiStoreRates", response)

      setIsLoading(false); // stop the spinner
    } finally {

    }
  }


  useEffect(() => {
    const fetch = async () => {
      try {
        setIsLoading(true); // Start the spinner
        debug("call api to get rates %o", { sessionId, sheetName });
        const data = await apiGetRates({ sessionId, sheetName, dir, index: Number(index), mapping, page });
        debug("api rates %o", data)
        setApiData(data)
      } finally {
        setIsLoading(false); // Stop the spinner
      }



    }

    fetch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId, sheetName, dir, index]);

  //
  if (!sessionId || !sheetName || !dir || !index || !mapping) { return null }

  if (!apiData) {
    return <div>Loading status...</div>;
  }





  return (
    <>
      <h3>Rates</h3>




      <div className="wraper" style={{ width: "100%", margin: "15px" }}>

        {isLoading ? <CircularProgress size={24} /> : <Table rows={apiData.rows} columns={apiData.columns}></Table>}


      </div>
      <Button
        style={{ width: "40%", margin: "5%" }}
        id={"sendToUBS"}
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        disabled={isLoading}
      >
        {isLoading ? <CircularProgress size={24} /> : "store UBS"}
      </Button >


    </>





  );
};

export default RateObjects;
