const localStorageKey = "tenderifyCache";
export const storeLocalStorage = (key: string, value: string | number | null) => {
  const mappingString = localStorage.getItem(localStorageKey) || "{}";
  const jsonMapping = JSON.parse(mappingString) || null;
  jsonMapping[key] = value;
  localStorage.setItem(localStorageKey, JSON.stringify(jsonMapping));
};
export const getLocalStorage = (key: string) => {
  const mappingString = localStorage.getItem(localStorageKey) || "{}";
  const jsonMapping = JSON.parse(mappingString) || null;
  return jsonMapping[key];
};
