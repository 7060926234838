import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import { CircularProgress } from '@mui/material';

import { apiGetCellData } from '../api';
const debug = require("debug")("formdata");

export default function FormDialog({ handleClose, formData }: { handleClose: any, formData: any }) {
  const { sessionId, sheetName, rowNumber, columnNumber } = formData;
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any | null>(null);

  useEffect(() => {
    async function fetchData() {
      try {
        debug("get data")
        const response = await apiGetCellData({ sessionId, sheetName, rowNumber, columnNumber });
        setData(response);
        debug("data field received", response);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, [sessionId, sheetName, rowNumber, columnNumber]);

  const [correctedData, setCorrectedData] = useState({
    ...formData,
    data,
    sessionIdSelect: true,
    sheetNameSelect: false,
    rowNumberSelect: false,
    columnNumberSelect: false,
  });
  debug("corrected data %o", correctedData);
  const setKeyValue = (key: string, value: boolean | string) => {
    debug("set key value %o",  {[key]: value});
    setCorrectedData({ ...correctedData, data, [key]: value });
  };

  return (
    <Dialog open={true} onClose={handleClose}>
      <DialogTitle>Data Correction</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {loading ? 'Loading...' : 'Correct the data and select where it should apply'}
        </DialogContentText>
        {loading ? (
          // Display a spinner while loading
          <CircularProgress size={24} />
        ) : (
          <FormGroup aria-label="position" row>
            {["sessionId", "sheetName", "rowNumber", "columnNumber"].map((key) => (
              <FormControlLabel
                value={correctedData[key]}
                key={key}
                control={
                  <Checkbox
                    id={`${key}Select`}
                    checked={correctedData[`${key}Select`]}
                    onChange={(e) => setKeyValue(`${key}Select`, e.target.checked)}
                  />
                }
                label={key}
                labelPlacement="top"
              />
            ))}
          </FormGroup>
        )}
        <TextField
          autoFocus
          margin="dense"
          defaultValue={formData.data}
          id="dataCorrection"
          label="corrected data"
          onChange={(e) => setKeyValue("dataCorrection", e.target.value)}
          type="text"
          fullWidth
          variant="standard"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={() => handleClose(correctedData)}>Submit</Button>
      </DialogActions>
    </Dialog>
  );
}