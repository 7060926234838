import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import ConvertXlsx from './ConvertXlsx';
import LocalStorageContent from './LocalStorageContent';
import Headers from './Headers';
// import Navbar from "./modules/NavBar";
import { ToastContainer } from 'react-toastify';
import { styled } from '@mui/system';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, CircularProgress } from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { apiGetStatus, IApiStatus } from './api';

import { default as FileUpload } from "./FileUpload/FileUpload";
// import Table from './Table';

import 'react-toastify/dist/ReactToastify.css';
import StatusModule from './Status';
import HeaderMapping from './HeaderMapping';
import RateObjects from './RateObjects';
import HorizontalStepper from './modules/Stepper';
import { getLocalStorage, storeLocalStorage } from './getLocalStorage';
const debug = require("debug")("main");

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  width: "90%",
  margin: "5px",
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: theme.spacing(4),
}));

const Heading = styled('h1')(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const App = () => {


  const queryParams = new URLSearchParams(window.location.search);
  const [sessionId, setSessionId] = useState<string | null>(getLocalStorage("sessionId"));
  const [sheetNames, setSheetNames] = useState<string | null>(getLocalStorage("sheetNames"));
  const [dir, setDir] = useState<string | null>(getLocalStorage("dir"));
  const [sheetName, setSheetName] = useState<string | null>(getLocalStorage("sheetName"));
  const [index, setIndex] = useState<string | null>(getLocalStorage("index"));
  const [mapping, setMapping] = useState<any | null>(getLocalStorage("mapping"));
  const [ratesStored, setRatesStored] = useState<boolean | null>(!!(getLocalStorage("ratesStored")));
  const [step, setStep] = useState<number>(0);
  const [statusData, setStatusData] = useState<IApiStatus>([]);

  // make wrapper function to give child
  // eslint-disable-next-line react-hooks/exhaustive-deps
  function wrapperSet(key: string, val: string | number | null) {

    let stringVal = val && String(val);
    debug("set val %o to key %o, stringVal %o", val, key, stringVal);
    if (val && typeof val === "object") stringVal = JSON.stringify(val)
    stringVal ? storeLocalStorage(key, String(stringVal)) : storeLocalStorage(key, null);

    if (val === null || typeof val === "string" || typeof val === "number" || typeof val === "object") {
      switch (key) {
        case "sessionId":
          setSessionId(String(val));
          break;
        case "sheetNames":
          setSheetNames(String(val));
          break;
        case "dir":
          setDir(String(val));
          break;
        case "sheetName":
          setSheetName(String(val));
          break;
        case "index":
          setIndex(String(val));
          break;
        case "mapping":
          setMapping(val);
          break;
        case "ratesStored":
          setRatesStored(!!val);
          break;
        case "step":
          debug("step set as %o", Number(val));
          setStep(Number(val));
          break;
        case "reset":
          wrapperSet("sessionId", null);
          wrapperSet("sheetNames", null);
          wrapperSet("sheetName", null);
          wrapperSet("dir", null);
          wrapperSet("index", null);
          wrapperSet("mapping", null);
          wrapperSet("ratesStored", null);
          wrapperSet("fileName", null);
          wrapperSet("step", 1);
          break;
        default:
          console.warn("not a valid key", key)

      }
    }
  };



  useEffect(() => {
    debug("recalculate step index %o", { sessionId, sheetNames, sheetName, index, dir, mapping, ratesStored });

    debug("check status");
    const run = async () => {
      let result: any[] | ((prevState: IApiStatus) => IApiStatus) = [];
      try {
        if (typeof sessionId !== "string") return false;
        const apiResult = await apiGetStatus({ sessionId });
        debug("api result %o", apiResult);
        if (apiResult && Array.isArray(apiResult)) {


          result = apiResult;
        }

      } catch (error) {
        console.error('Error fetching status:', error);
      }
      finally {
        const sheetNames = (result.filter(el => el.sheetName !== "*").map(el => el.sheetName).join(",")) || null;
        debug("set sheetnames", sheetNames);
        setSheetNames(sheetNames && String(sheetNames));
        setStatusData(result);
        const statusSheet = result.find(el => el.sheetName === sheetName);
        debug("sheetName %o statusSheet %o", sheetName, statusSheet);

        const activeStep = statusSheet?.activeStep ? Number(statusSheet?.activeStep) : 1;
        debug("step local %o vs step db : %o", step, activeStep);

        setStep(Number(step || activeStep));
      }


    }

    run();

    // setStep(calculateStep({ sessionId, sheetNames, sheetName, index, dir, mapping, ratesStored }));

  }, [dir, index, mapping, ratesStored, sessionId, sheetName, sheetNames, step])










  queryParams.forEach((value, key) => {
    debug("search params %o", { key, value });
    if (key === "reset") {
      wrapperSet("reset", null);


      window.location.search = "reset-done";
    }
    if (["sessionId"].includes(key)) {
      wrapperSet(key, value);
    }
  })
  const statusSheet = statusData.find(el => el.sheetName === sheetName);
  const stepDb = statusSheet?.activeStep ? Number(statusSheet?.activeStep) + 1 : 0;

  return (
    <div className="App">
      {/* <Navbar /> */}
      <Accordion defaultExpanded={!sheetName} >
        <AccordionSummary>
          <Heading>sheet overview</Heading>
          <Button
            variant="text"
            id="resetAll"
            color="error"
            onClick={() => wrapperSet('reset', null)}
          >
            {!statusData ? <CircularProgress size={24} /> : "RESET"}
          </Button >
        </AccordionSummary>
        <AccordionDetails >
          {/* <Table /> */}
          <StatusModule step={step} sessionId={sessionId} statusData={statusData} setKeyValue={wrapperSet} sheetName={sheetName} />
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={true}>
        <AccordionSummary>
          <Heading>{sheetName}</Heading>
        </AccordionSummary>
        <AccordionDetails >
          <Container>
            <HorizontalStepper completedStep={stepDb} currentStep={step} setKeyValue={wrapperSet} />
            {step === null ? <CircularProgress size={24} /> : null}
            {step === 1 || step === 0 ? <FileUpload step={step} setKeyValue={wrapperSet} /> : null}
            {step === 2 ? <ConvertXlsx step={step} sessionId={sessionId} sheetNames={sheetNames} /> : null}
            {step === 3 ? <Headers step={step} sessionId={sessionId} sheetName={sheetName} setKeyValue={wrapperSet} /> : null}
            {step === 4 ? <HeaderMapping sessionId={sessionId} step={step} sheetName={sheetName} dir={dir} index={index} setKeyValue={wrapperSet} mapping={mapping} /> : null}
            {step === 5 ? <RateObjects sessionId={sessionId} step={step} sheetName={sheetName} dir={dir} index={index} mapping={mapping} setKeyValue={wrapperSet} /> : null}
            {step >= 6 ? <Box component="span" sx={{ p: 2, border: '1px dashed grey' }}>     FINISHED <Link to={`/zips/${sessionId}`}>check sample zips</Link></Box> : null}

            {/* <Table /> */}
          </Container>
        </AccordionDetails>
      </Accordion>
      {/* meta data */}
      <Accordion >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Heading>status</Heading>

        </AccordionSummary>

        <AccordionDetails>
          <LocalStorageContent storeLocalStorage={storeLocalStorage} getLocalStorage={getLocalStorage} />
        </AccordionDetails>
      </Accordion>
      <ToastContainer />
    </div>
  );
};

export default App;