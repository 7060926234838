import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { apiXlsxToBq } from './api/index'
import { toast } from 'react-toastify';
import { styled } from '@mui/system';
const debug = require("debug")("convert");

const StartButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));


const ConvertXlsx = ({ step, sessionId ,sheetNames}: { step:number, sessionId: string | null , sheetNames:string|null}) => {
  const [isLoading, setIsLoading] = useState(false);
  const startConversion = async () => {

    setIsLoading(true); // Start the spinner

    try {
      debug("start conversion for %o", sessionId);
      if(sessionId){
        await apiXlsxToBq({ sessionId });
        toast.success('File upload successful');
      } 
      

    } catch (error) {
      console.error('Error uploading file:', error);
      toast.error('An error occurred while uploading the file.');
    }
    setIsLoading(false); // Stop the spinner

  }
 
  useEffect(() => {
    // Auto start conversion if no sheetnames set.
    if (!sheetNames && sessionId) {
      startConversion();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId]); // The empty dependency array ensures that this effect runs only once on mount.


  if (!sessionId) {
    return null; // Render nothing if sessionId is not present
  }

  return (
    <div>
      <h3>STEP {step}: Convert xlsx for sessionId</h3>
      <Box textAlign='center'>
        <StartButton variant="contained" onClick={startConversion} disabled={isLoading}>

          {isLoading ? <CircularProgress size={24} /> : 'Start'}
        </StartButton>
      </Box>
    </div>
  );
};

export default ConvertXlsx;

